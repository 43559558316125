import React, { useState } from "react";
import SectionTitle from "../../components/SectionTitle";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import sImg1 from "../../images/story/julez.jpg";
import sImg2 from "../../images/story/alstergym.jpeg";
import sImg3 from "../../images/story/old.jpg";
import sImg4 from "../../images/story/antrag.jpeg";
import sImg5 from "../../images/story/tamo.jpg";

import sIcon1 from "../../images/story/1.png";
import sIcon2 from "../../images/story/2.png";
import sIcon3 from "../../images/story/3.png";
import sIcon4 from "../../images/story/4.png";

import Shape from "../../images/story/shape.jpg";

const StorySection = (props) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const Tabs = [
    {
      Id: "1",
      tabItem: "Kennenlernen",
    },
    {
      Id: "2",
      tabItem: "Wiedersehen",
    },
    {
      Id: "3",
      tabItem: "Erstes Date",
    },
    {
      Id: "4",
      tabItem: "Antrag",
    },
    {
      Id: "5",
      tabItem: "Baby-Glück",
    },
  ];

  const Story = [
    {
      Id: "1",
      sImg: sImg1,
      sIcon: sIcon1,
      Shape: Shape,
      sTitle: "Das erste Kennenlernen",
      date: "14. Juli 2012",
      description:
        "Nachdem Gotje und Lars über sieben Jahre auf die gleiche Schule gingen und sich nie richtig wahrnahmen, war es ein gemeinsamer Freund, der Lars ungefragt mit zu der Abschiedsfeier von Jule nahm. Gotje war als Jules gute Freundin natürlich auch da und so kamen die Beiden das erste Mal in Kontakt und lernten sich kennen.",
    },
    {
      Id: "2",
      sImg: sImg2,
      sIcon: sIcon1,
      Shape: Shape,
      sTitle: "Das große Wiedersehen",
      date: "8. Juni 2013",
      description:
        "Kurz nach Jules Abschied ging auch Gotje für ein Jahr ins Ausland. In dem Jahr hatten Lars und Gotje keinen Kontakt und die Freude war umso größer, als sie sich auf einem Abiball ein Jahr später wiedersahen. Gerüchten zufolge tanzten die Beiden wie junge Götter und waren der Hingucker des Abends.",
    },
    {
      Id: "3",
      sImg: sImg3,
      sIcon: sIcon2,
      Shape: Shape,
      sTitle: "Die erste Zweisamkeit",
      date: "Ende Juni 2013",
      description:
        'Der sonst eher zurückhaltende Lars überwand sich und kontaktierte die grazile Gotje um weitere Treffen zu arrangieren. Es lief gerade die neue Tanzshow "Got to dance" an, welche sich optimal für einen gemeinsamen Abend im Hause Raschke anbot. Gotje wickelte Lars so um den Finger, dass er sogar Wein trank.',
    },
    {
      Id: "4",
      sImg: sImg4,
      sIcon: sIcon3,
      Shape: Shape,
      sTitle: "Die entscheidende Frage",
      date: "24. Juli 2022",
      description:
        'In der folgenden Zeit trafen sich die beiden verliebten Schönheiten sehr oft und näherten sich immer weiter an. Auf Gotjes Frage, ob man jetzt zusammen sei, reagierte Lars ganz souverän mit: " Wir könn`s ja mal probieren." Nach genau zehn Jahren Probezeit schaffte es Lars endlich bei einem Picknick am Glücksburger Schloss einen Verlobungsring in einer Avocado zu servieren. Erwartungsgemäß freute sich Gotje sehr, denn sie liebt Avocados.',
    },
    {
      Id: "5",
      sImg: sImg5,
      sIcon: sIcon4,
      Shape: Shape,
      sTitle: "Aus Zwei mach Drei",
      date: "4. Mai 2023",
      description:
        "Das in Glück schwelgende Paar hielt es für eine großartige Idee, ihre Gene zu kreuzen, um einen noch atemberaubenderen Menschen zu erschaffen. Das Ergebnis kann sich sehen lassen und hört auf den Namen Tamo.",
    },
  ];

  return (
    <section className="wpo-story-section section-padding" id="story">
      <div className="container">
        <SectionTitle MainTitle={"Unsere Liebesgeschichte"} />
        <div className="row align-items-center justify-content-center">
          <div className="col col-lg-12 col-12">
            <div className="tab-area">
              <div className="tablinks">
                <Nav tabs className="service-thumbs-outer">
                  {Tabs.map((tab, titem) => (
                    <NavItem key={titem}>
                      <NavLink
                        className={`service-thumb ${classnames({
                          active: activeTab === tab.Id,
                        })}`}
                        onClick={() => {
                          toggle(tab.Id);
                        }}
                      >
                        {tab.tabItem}
                        <span className="number">{tab.tabNumber}</span>
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
              </div>
              <TabContent activeTab={activeTab}>
                {Story.map((story, Sitem) => (
                  <TabPane tabId={story.Id} key={Sitem}>
                    <div className="wpo-story-item">
                      <div className="wpo-story-img">
                        <img src={story.sImg} alt="" />
                      </div>
                      <div className="wpo-story-content">
                        <div className="wpo-story-content-inner">
                          <span>
                            <img src={story.sIcon} alt="" />
                          </span>
                          <h2>{story.sTitle}</h2>
                          <span>{story.date}</span>
                          <p>{story.description}</p>
                          <div className="border-shape">
                            <img src={story.Shape} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPane>
                ))}
              </TabContent>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StorySection;
