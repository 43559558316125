import React, { Fragment } from "react";
import Navbar from "../../components/Navbar";
import Hero from "../../components/hero";
import StorySection from "../../components/StorySection";
import CoupleSection from "../../components/CoupleSection";
import EventSection from "../../components/EventSection";
import RSVP from "../../components/RSVP";
import UnterkunftSection from "../../components/Unterkunft";
import TrauzeugenSection from "../../components/TrauzeugenSection";
import Scrollbar from "../../components/scrollbar";
import Footer from "../../components/footer";

const HomePage = (props) => {
  return (
    <Fragment>
      <Navbar hclass={"wpo-site-header-s1"} />
      <Hero />
      <CoupleSection />
      <StorySection />
      <EventSection />
      <RSVP
        guests={props.guests}
        completed={props.completed}
        bookingNo={props.bookingNo}
      />
      <UnterkunftSection />
      <TrauzeugenSection />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default HomePage;
