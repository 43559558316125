import React, { Component } from "react";
import { Link } from "react-router-dom";
import coupleImg1 from "../../images/couple/c1.jpeg";

class CoupleSection extends Component {
  render() {
    return (
      <section className="couple-section section-padding" id="couple">
        <div className="container">
          <div className="row align-items-center">
            <div className="col col-xs-12">
              <div className="couple-area clearfix">
                <div className="text-grid bride">
                  <h3>Gotje Ellebrecht</h3>
                  <p>
                    Die liebevolle Frohnatur mit bemerkenswerter Intelligenz
                    versteht es Menschen zu verbinden. Mit ihrer warmherzigen
                    Ausstrahlung und einem bezaubernden Lachen füllt sie jeden
                    Raum mit Freude. Dazu sieht sie gar nicht mal so schlecht
                    aus.
                  </p>
                </div>
                <div className="middle-couple-pic">
                  <div className="middle-couple-pic-inner">
                    <img src={coupleImg1} alt="" />
                  </div>
                </div>
                <div className="text-grid groom">
                  <h3>Lars Raschke</h3>
                  <p>
                    Der gutaussehende und intelligente Mann hat einen Hang zum
                    Perfektionismus mit leichten autistischen Zügen. Er hat
                    einen sehr trockenen Humor, mit dem er seine Verlobte auch
                    noch nach 10 Jahren zum Lachen bringt.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CoupleSection;
