import React, { Component } from "react";
import axios from "axios";
import SectionTitle from "../../components/SectionTitle";

import vec1 from "../../images/contact/1.png";
import vec2 from "../../images/contact/2.png";

import { CheckCircle } from "@material-ui/icons";

class RSVP extends Component {
  state = {
    bookingNo: "",
    bookingCompleted: false,
    email: "",
    music: "",
    guests: [],
    selectedStep: 0,
    error: {},
  };

  componentDidMount = () => {
    this.setState({
      guests: this.props.guests,
      bookingCompleted: this.props.completed,
      bookingNo: this.props.bookingNo,
    });
  };

  // handle change email
  changeHandler = (e) => {
    const error = this.state.error;
    error[e.target.name] = "";

    this.setState({
      [e.target.name]: e.target.value,
      error,
    });
  };

  // handle guest attendance change
  changeGuestAttendance = (e, index) => {
    const guests = this.state.guests;
    guests[index].attendingParty = e.target.value;

    this.setState({
      guests,
    });
  };

  // handle guest attendance change
  changeGuestBreakfast = (e, index) => {
    const guests = this.state.guests;
    guests[index].attendingBreakfast = e.target.value;

    this.setState({
      guests,
    });
  };

  // handle guest meal change
  changeGuestMeal = (e, index) => {
    const guests = this.state.guests;
    guests[index].meal = e.target.value;

    this.setState({
      guests,
    });
  };

  // handle form submit
  subimtHandler = (e) => {
    e.preventDefault();

    const { bookingNo, email, music, guests, error } = this.state;

    if (bookingNo.length !== 6) {
      error.bookingNo = "Bitte gib deine Buchungsnummer ein.";
    } else {
      error.bookingNo = "";
    }

    for (let i = 0; i < guests.length; i++) {
      if (guests[i].attendingParty === "") {
        error.general = `Bitte gib die Anwesenheit für die Feier von ${guests[i].name} an.`;
        break;
      } else if (guests[i].attendingBreakfast === "") {
        error.general = `Bitte gib die Anwesenheit für das Frühstück von ${guests[i].name} an.`;
        break;
      } else {
        error.general = "";
      }
    }
    if (error.bookingNo || error.email || error.general) {
      this.setState({
        error,
      });
    } else {
      // send data to rest api
      axios({
        method: "post",
        url: "https://gotje-lars.de/api/v1/bookings/respond-to-invitation",
        data: {
          bookingNo,
          guests,
          email,
          songs: music,
        },
      })
        .then((response) => {
          // set state to done
          this.setState({
            error: {},
            bookingCompleted: true,
          });
        })
        .catch((error) => {
          this.setState({
            error: {
              general: error.response.data,
            },
          });
        });
    }
  };

  render() {
    const {
      bookingNo,
      email,
      music,
      guests,
      error,
      selectedStep,
      bookingCompleted,
    } = this.state;
    const guest = guests[selectedStep];

    let noOfGuests = 0;
    for (let i = 0; i < guests.length; i++) {
      if (guests[i].attendingParty === "confirm") {
        noOfGuests++;
      }
    }

    return (
      <section className={`wpo-contact-section ${this.props.pt}`} id="RSVP">
        <div className="container">
          <div className="wpo-contact-section-wrapper">
            <div className="wpo-contact-form-area">
              <SectionTitle MainTitle={"Nimmst du teil?"} />
              {!bookingCompleted ? (
                <form onSubmit={this.subimtHandler} className="form">
                  <div className="row">
                    <p>{error.general ?? ""}</p>
                    <div>
                      <label>Deine Buchungsnummer</label>
                      <div className="form-field">
                        <input
                          value={bookingNo}
                          className="form-control"
                          type="text"
                          name="bookingNo"
                          maxLength={6}
                          placeholder="Buchungsnummer"
                          disabled
                        />
                        <p>{error.bookingNo ? error.bookingNo : ""}</p>
                      </div>
                    </div>

                    {guests.length > 0 && (
                      <>
                        <hr
                          style={{ height: "2px", backgroundColor: "black" }}
                        />
                        <p
                          style={{
                            color: "#848892",
                            float: "right",
                            fontWeight: "bold",
                          }}
                        >
                          Schritt {selectedStep + 1}/{guests.length + 1}
                        </p>
                      </>
                    )}

                    {guests.length > 0 ? (
                      selectedStep === guests.length ? (
                        <div>
                          <hr
                            style={{ height: "2px", backgroundColor: "black" }}
                          />
                          <label>Deine E-Mail</label>
                          <div className="form-field">
                            <input
                              onChange={this.changeHandler}
                              value={email}
                              type="email"
                              className="form-control"
                              name="email"
                              placeholder="Email eingeben ..."
                            />
                            <p>{error.email ? error.email : ""}</p>
                          </div>
                          <label>Musikwünsche</label>
                          <div className="form-field">
                            <textarea
                              onChange={this.changeHandler}
                              value={music}
                              className="form-control music-textarea"
                              name="music"
                              placeholder="Musikwünsche eingeben..."
                              style={{ height: "150px" }}
                            />
                          </div>
                        </div>
                      ) : (
                        <div>
                          <label>Ich bin ...</label>
                          <div className="form-field">
                            <input
                              value={guest.name}
                              type="text"
                              className="form-control"
                              name="name"
                              placeholder="Name"
                              disabled
                            />
                          </div>
                          <div className="form-field">
                            <label>An der Feier werde ich ...</label>
                            <select
                              value={guest.attendingParty}
                              onChange={(e) =>
                                this.changeGuestAttendance(e, selectedStep)
                              }
                              className="form-control"
                              name="attendingParty"
                              placeholder="An der Feier werde ich ..."
                            >
                              <option value="" disabled>
                                Bitte auswählen
                              </option>
                              <option value="confirm">teilnehmen.</option>
                              <option value="reject">
                                leider nicht teilnehmen können.
                              </option>
                            </select>
                          </div>
                          <div className="form-field">
                            <label>An dem Frühstück werde ich ...</label>
                            <select
                              value={guest.attendingBreakfast}
                              onChange={(e) =>
                                this.changeGuestBreakfast(e, selectedStep)
                              }
                              className="form-control"
                              name="attendingBreakfast"
                              placeholder="An dem Frühstück werde ich ..."
                            >
                              <option value="" disabled>
                                Bitte auswählen
                              </option>
                              <option value="confirm">
                                teilnehmen (~25€).
                              </option>
                              <option value="reject">nicht teilnehmen.</option>
                            </select>
                          </div>
                          <label>Besonderheiten</label>
                          <div className="form-field">
                            <input
                              onChange={(e) =>
                                this.changeGuestMeal(e, selectedStep)
                              }
                              value={guest.meal}
                              type="text"
                              className="form-control"
                              name="meal"
                              placeholder="Unverträglichkeiten / Vegan ..."
                            />
                          </div>
                        </div>
                      )
                    ) : error.invalidBooking ? (
                      <p>
                        Buchung nicht gefunden. Bitte gib eine gültige
                        Buchungsnummer an.
                      </p>
                    ) : (
                      <p style={{ color: "#848892" }}>
                        Gib bitte eine gültige Buchungsnummer an, um für deine
                        Buchung zu- oder abzusagen.
                      </p>
                    )}

                    {/* button area */}
                    <div className="submit-area">
                      <div className="form-submit">
                        {guests.length > 0 ? (
                          <>
                            {selectedStep > 0 && (
                              <button
                                type="button"
                                className="theme-btn-s3"
                                style={{ marginRight: "10px" }}
                                onClick={() => {
                                  this.setState({
                                    selectedStep: selectedStep - 1,
                                  });
                                }}
                              >
                                Zurück
                              </button>
                            )}
                            {selectedStep !== guests.length ? (
                              <button
                                type="button"
                                className="theme-btn-s3"
                                disabled={guests.length === 0}
                                onClick={() => {
                                  this.setState({
                                    selectedStep: selectedStep + 1,
                                  });
                                }}
                              >
                                Weiter
                              </button>
                            ) : (
                              <>
                                <button type="submit" className="theme-btn-s3">
                                  Absenden
                                </button>
                              </>
                            )}
                          </>
                        ) : (
                          <button
                            className="theme-btn-s3"
                            disabled
                            style={{
                              cursor: "not-allowed",
                              backgroundColor: "#848892",
                            }}
                          >
                            Absenden
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              ) : (
                <div style={{ textAlign: "center" }}>
                  <h2 style={{ color: "#848892" }}>
                    Vielen Dank für deine Rückmeldung!
                  </h2>
                  <CheckCircle
                    className="text-success"
                    style={{ fontSize: 100 }}
                  />
                  {noOfGuests === guests.length ? (
                    <p style={{ color: "#848892" }}>
                      Wir haben deine Rückmeldung erhalten und freuen uns,
                      {noOfGuests === 1 ? " dich " : " euch "}
                      auf unserer Hochzeit begrüßen zu dürfen.
                    </p>
                  ) : noOfGuests === 0 ? (
                    <p style={{ color: "#848892" }}>
                      Wir haben deine Rückmeldung erhalten und finden es schade,
                      dass {guests.length === 1 ? " du " : " ihr "} nicht kommt.
                      Hoffenlich sehen wir uns trotzdem bald mal wieder.
                    </p>
                  ) : (
                    <p style={{ color: "#848892" }}>
                      Wir haben deine Rückmeldung erhalten und bedauern, dass
                      ihr nicht alle kommen könnt. Wir freuen uns aber,
                      diejenigen von euch, die kommen, auf unserer Hochzeit
                      begrüßen zu dürfen.
                    </p>
                  )}
                  <p style={{ color: "#848892" }}>
                    Sollte sich an deinen Angaben etwas ändern, kannst du uns
                    gerne direkt kontaktieren.
                  </p>
                </div>
              )}
              <div className="border-style"></div>
            </div>
            <div className="vector-1">
              <img src={vec1} alt="" />
            </div>
            <div className="vector-2">
              <img src={vec2} alt="" />
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default RSVP;
