import React from "react";
import { Link } from "react-router-dom";

import SectionTitle from "../SectionTitle";
import sImg2 from "../../images/zeugen/jule.jpg";
import sImg3 from "../../images/zeugen/anna.jpg";
import sImg4 from "../../images/zeugen/mats.jpeg";

const Persons = [
  {
    Simg: sImg4,
    title: "mats",
    li1: "Als langjähriger Freund und treuer Wegbegleiter hält Mats Lars schon seit der 7. Klasse aus.",
    insta: "mats_brandt",
  },
  {
    Simg: sImg3,
    title: "Anna",
    li1: "Ähn, Julez und Gödel sind seit der 7. Klasse ein unzertrennliches Dreier-Gespann.",
    insta: "jahn.lena",
  },
  {
    Simg: sImg2,
    title: "Jule",
    li1: "Ähn, Julez und Gödel sind seit der 7. Klasse ein unzertrennliches Dreier-Gespann.",
    insta: "jule_wangen",
  },
];

const TrauzeugenSection = (props) => {
  return (
    <section
      className={`wpo-event-section wpo-zeugen-section section-padding ${props.eClass}`}
      id="trauzeugen"
    >
      <div className="container">
        <SectionTitle MainTitle={"Die Trauzeugen"} />
        <div className="wpo-event-wrap">
          <div className="row">
            {Persons.map((person, eitem) => (
              <div className="col col-lg-4 col-md-3 col-12" key={eitem}>
                <div className="wpo-event-item">
                  <div className="wpo-event-img">
                    <img src={person.Simg} alt="" />
                  </div>
                  <div className="wpo-event-text">
                    <h2>{person.title}</h2>
                    <ul>
                      <li>{person.li1}</li>
                      <li>
                        <a
                          href={`https://www.instagram.com/${person.insta}`}
                          target="_blank"
                          rel="noreferrer"
                          style={{ color: "#A3888C" }}
                        >
                          <i className="ti-instagram"></i>
                        </a>
                        <a
                          href="mailto:trauzeugen.gotje.lars@gmail.com"
                          target="_blank"
                          rel="noreferrer"
                          style={{ marginLeft: "10px", color: "#A3888C" }}
                        >
                          <i className="ti-email"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrauzeugenSection;
