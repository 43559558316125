import React from "react";
import SectionTitle from "../../components/SectionTitle";

const locations = [
  {
    name: "Schlüter",
    title: "Hotel",
    distance: "ca.7 min",
    description: "In unmittelbarer Nähe zur Feier- sowie Frühstückslocation.",
    link: "https://schlueter-wankendorf.de/hotel/reservierung/",
  },
  {
    name: "Booking.com",
    title: "Gemischt",
    distance: "n/a",
    description: "Auswahl an Ferienwohnungen und Hotels in der Umgebung.",
    link: "https://www.booking.com/searchresults.de.html?ss=Ruhwinkel%2C+Schleswig-Holstein%2C+Deutschland&efdco=1&label=de-de-booking-desktop-Rkfkc6G4k*BOUrb26dySJQS652829000617%3Apl%3Ata%3Ap1%3Ap2%3Aac%3Aap%3Aneg%3Afi%3Atikwd-334108349%3Alp9043607%3Ali%3Adec%3Adm&aid=2311236&lang=de&sb=1&src_elem=sb&src=index&dest_id=-1853787&dest_type=city&ac_position=0&ac_click_type=b&ac_langcode=de&ac_suggestion_list_length=1&search_selected=true&search_pageview_id=15376b05809c00c4&ac_meta=GhAxNTM3NmIwNTgwOWMwMGM0IAAoATICZGU6CXJ1aHdpbmtlbEAASgBQAA%3D%3D&checkin=2024-10-05&checkout=2024-10-06&group_adults=2&no_rooms=1&group_children=0",
  },
  {
    name: "Whiteman",
    title: "Hotel",
    distance: "ca.20 min",
    description:
      "Direkt am Plöner See gelegen und in der Nähe zum optionalen Katerfrühstück am Folgetag.",
    link: "https://designhotel-whitman.de/",
  },
  {
    name: "Hildebrandt",
    title: "Hotel",
    distance: "ca. 20 min",
    description: "Stadthotel in Neumünster unweit von der A7 entfernt.",
    link: "https://www.hildebrandts-hotel.de/de/",
  },
  {
    name: "Wankendorfer Seegebiet",
    title: "Ferienwohnungen",
    distance: "n/a",
    description: "Erweiterte Auswahl an Ferienwohnungen in der Umgebung.",
    link: "http://www.urlaub-wankendorfer-seengebiet.de/",
  },
  {
    name: "George Glamp",
    title: "Glamping",
    distance: "ca. 12 min",
    description:
      "Schicke Tiny-Häuser, Tipi-Zelte und Iglus für min. 2 Übernachtungen direkt beim optionalen Katerfrühstück.",
    link: "https://www.george-glamp.com/perdoeler-muhle/",
  },
  {
    name: "An der Location",
    title: "Campen",
    distance: "0 min",
    description:
      "Übernachten im eigenen Camper direkt an der Location ist für eine Nacht möglich, jedoch stehen keine sanitären Anlagen zur Verfügung.",
    link: "https://www.george-glamp.com/perdoeler-muhle/",
  },
];

const UnterkunftSection = (props) => {
  return (
    <section
      className={`wpo-team-section section-padding ${props.pt}`}
      id="unterkunft"
    >
      <div className="container">
        <SectionTitle topTitle={"Unterkünfte"} MainTitle={"Unterkünfte"} />
        <div className="wpo-team-wrap">
          <div className="row">
            {locations.slice(0, 8).map((location, lo) => (
              <div className="col-lg-3 col-md-4 col-sm-6 col-12" key={lo}>
                <a href={location.link} target="_blank" rel="noreferrer">
                  <div className="wpo-team-item">
                    <div className="wpo-team-img">
                      <img src={location.tImg} alt="" />
                    </div>
                    <div className="wpo-team-text">
                      <h3>{location.name}</h3>
                      <span>{location.title}</span>
                      <ul
                        style={{
                          display: "block",
                          textAlign: "center",
                          color: "#848892",
                        }}
                      >
                        <li style={{ marginBottom: "12px" }}>
                          {location.description}
                        </li>
                        <li>Entfernung: {location.distance}</li>
                        <li
                          style={{
                            color: "#a3888c",
                            fontWeight: "bold",
                            marginTop: "12px",
                          }}
                        >
                          Öffnen
                        </li>
                      </ul>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default UnterkunftSection;
