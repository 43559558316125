import React, { Component } from "react";
import { connect } from "react-redux";
import MobileMenu from "../MobileMenu";
import min3 from "../../images/love.png";
import { removeFromCart } from "../../store/actions/action";
import { Link } from "react-scroll";
import { NavLink } from "react-router-dom";
import HeaderTopbar from "../HeaderTopbar";
import { totalPrice } from "../../utils";

class Header extends Component {
  state = {
    isSearchShow: false,
    isCartShow: false,
  };

  searchHandler = () => {
    this.setState({
      isSearchShow: !this.state.isSearchShow,
    });
  };
  cartHandler = () => {
    this.setState({
      isCartShow: !this.state.isCartShow,
    });
  };

  render() {
    const { isSearchShow, isCartShow } = this.state;

    const SubmitHandler = (e) => {
      e.preventDefault();
    };

    const ClickHandler = () => {
      window.scrollTo(10, 0);
    };

    const { carts } = this.props;

    return (
      <header id="header" className={this.props.topbarBlock}>
        <HeaderTopbar />
        <div className={`wpo-site-header ${this.props.hclass}`}>
          <nav className="navigation navbar navbar-expand-lg navbar-light">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                  <div className="mobail-menu">
                    <MobileMenu />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-6">
                  <div className="navbar-header">
                    <NavLink
                      onClick={ClickHandler}
                      className="navbar-brand logo"
                      to="/home"
                    >
                      Hochze
                      <span>
                        i<i className="fa fa-heart" aria-hidden="true"></i>
                      </span>
                      t
                    </NavLink>
                  </div>
                </div>
                <div
                  className="col-lg-6 col-md-1 col-1"
                  style={{ width: "70%" }}
                >
                  <div
                    id="navbar"
                    className="collapse navbar-collapse navigation-holder"
                  >
                    <button className="menu-close">
                      <i className="ti-close"></i>
                    </button>
                    <ul className="nav navbar-nav mb-2 mb-lg-0">
                      <li>
                        <Link
                          activeClass="active"
                          to="couple"
                          spy={true}
                          smooth={true}
                          offset={-100}
                          duration={500}
                        >
                          Paar
                        </Link>
                      </li>
                      <li>
                        <Link
                          activeClass="active"
                          to="story"
                          spy={true}
                          smooth={true}
                          duration={500}
                        >
                          Geschichte
                        </Link>
                      </li>
                      <li>
                        <Link
                          activeClass="active"
                          to="events"
                          spy={true}
                          smooth={true}
                          offset={-80}
                          duration={500}
                        >
                          Events
                        </Link>
                      </li>
                      <li>
                        <Link
                          activeClass="active"
                          to="RSVP"
                          spy={true}
                          smooth={true}
                          duration={500}
                        >
                          Formular
                        </Link>
                      </li>
                      <li>
                        <Link
                          activeClass="active"
                          to="unterkunft"
                          spy={true}
                          smooth={true}
                          duration={500}
                        >
                          Unterkünfte
                        </Link>
                      </li>
                      <li>
                        <Link
                          activeClass="active"
                          to="trauzeugen"
                          spy={true}
                          smooth={true}
                          duration={500}
                        >
                          Trauzeugen
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    carts: state.cartList.cart,
  };
};

export default connect(mapStateToProps, { removeFromCart })(Header);
