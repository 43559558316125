import axios from "axios";
import React, { useState } from "react";
import Homepage from "../../main-component/HomePage";

const Hero5 = (props) => {
  const [bookingNo, setBookingNo] = useState("");
  const [name, setName] = useState("");
  const [bookingCompleted, setBookingCompleted] = useState(false);
  const [guests, setGuests] = useState([]);
  const [error, setError] = useState("");

  // handle booking number change
  const onChangeBookingNo = (e) => {
    if (isNaN(e.target.value)) return;
    setBookingNo(e.target.value);
  };

  const getBooking = (e) => {
    e.preventDefault();

    axios({
      method: "post",
      url: "https://gotje-lars.de/api/v1/bookings/get-booking",
      data: {
        bookingNo,
        name,
      },
    })
      .then((response) => {
        setBookingCompleted(response.data.bookingCompleted);
        setGuests(response.data.guests);
      })
      .catch((error) => {
        if (error.response.data) return setError(error.response.data);
        if (error.message) return setError(error.message);
        setError("Unerwarteter Fehler. Bitte sage Lars Bescheid");
      });
  };

  if (guests.length > 0) {
    return (
      <Homepage
        guests={guests}
        completed={bookingCompleted}
        bookingNo={bookingNo}
      />
    );
  } else {
    return (
      <section className="static-hero-s3">
        <div className="hero-container">
          <div className="hero-inner">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6">
                  <div
                    className="wpo-event-item"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                  >
                    <div className="wpo-event-text">
                      <h2 style={{ color: "white" }}>Gotje & Lars</h2>
                      <p style={{ color: "white" }}>Wir heiraten</p>
                      <p style={{ color: "white" }}>
                        Bitte gib deine Buchungsnummer und deinen vollen Namen
                        ein um die Einladung zu öffnen.
                      </p>
                      <form onSubmit={getBooking} className="form">
                        <div className="row">
                          <p style={{ color: "red" }}>{error}</p>
                          <div style={{ color: "white", marginBottom: "20px" }}>
                            <label>Meine Buchungsnummer</label>
                            <div className="form-field">
                              <input
                                value={bookingNo}
                                onChange={onChangeBookingNo}
                                className="form-control"
                                type="text"
                                name="bookingNo"
                                maxLength={6}
                                placeholder="Buchungsnummer"
                              />
                            </div>
                          </div>
                          <div style={{ color: "white", marginBottom: "20px" }}>
                            <label>Vor- und Nachname</label>
                            <div className="form-field">
                              <input
                                value={name}
                                type="text"
                                className="form-control"
                                name="name"
                                placeholder="Name"
                                onChange={(e) => setName(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="submit-area">
                            <div className="form-submit">
                              <button className="theme-btn-s3">Öffnen</button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
};

export default Hero5;
