import React from "react";
import SectionTitle from "../SectionTitle";
import sImg1 from "../../images/event/1.jpg";
import sImg2 from "../../images/event/sekt.jpg";
import sImg3 from "../../images/event/kf.jpg";
import LocationMap from "../Modal";

const Events = [
  // {
  //   Simg: sImg1,
  //   title: "DIE TRAUUNG",
  //   li1: "Samstag, 21. September 2024 15:00",
  //   li2: "Schloss Glücksburg, Schlosshof 0, 24960 Glücksburg",
  //   mapsLink:
  //     "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2298.042111675586!2d9.540827877222217!3d54.83194506253924!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b3415f6b614bc7%3A0x3f885bafdb438995!2sSchloss%20Gl%C3%BCcksburg!5e0!3m2!1sde!2sde!4v1707483858295!5m2!1sde!2sde",
  // },
  {
    Simg: sImg2,
    title: "DIE FEIER",
    li1: "Samstag, 5. Oktober 2024 17:00 - 4:00",
    li2: "Kuhlounge, Eichholz 11, 24601 Ruhwinkel",
    mapsLink:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2339.8027209005677!2d10.178619094320515!3d54.094973047587125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b245d973727c9d%3A0xe077ffd6e07a21d4!2sK%C3%BCchenperle%20Partyservice%20Inh.%20Britta%20M%C3%BCller!5e0!3m2!1sde!2sde!4v1711896752394!5m2!1sde!2sde",
  },
  {
    Simg: sImg3,
    title: "Katerfrühstück",
    li1: "Sonntag, 6. Oktober 2024 9:00 - 11:00",
    li2: "George Glamp, Perdöler Mühle 3, 24601 Belau",
    mapsLink:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d18101.906104645044!2d10.181090737425441!3d54.105357523775105!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b2440922671bcf%3A0x9be3ea2070f4a218!2sGeorge%20Glamp%20-%20Campingplatz%20%26%20Ferien-Resort%20Perdoeler%20M%C3%BChle!5e0!3m2!1sde!2sde!4v1711897069588!5m2!1sde!2sde",
  },
];

const EventSection = (props) => {
  return (
    <section
      className={`wpo-event-section section-padding ${props.eClass}`}
      id="events"
    >
      <div className="container">
        <SectionTitle MainTitle={"Ort und Zeit"} />
        <div className="wpo-event-wrap">
          <div className="row">
            {Events.map((event, eitem) => (
              <div className="col col-lg-6 col-md-6 col-12" key={eitem}>
                <div className="wpo-event-item">
                  <div className="wpo-event-img">
                    <img src={event.Simg} alt="" />
                  </div>
                  <div className="wpo-event-text">
                    <h2>{event.title}</h2>
                    <ul>
                      <li>{event.li1}</li>
                      <li>{event.li2}</li>
                      <li>
                        <LocationMap mapsLink={event.mapsLink} />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default EventSection;
