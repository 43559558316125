import React from "react";

const Footer = () => {
  return (
    <footer className="wpo-site-footer">
      <div className="wpo-lower-footer">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <p className="copyright"> &copy; 2024 Raschid</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
